import { Plus } from "lucide-react";
import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "../../../Modal";
import XHR from "@uppy/xhr-upload";
import Uppy from "@uppy/core";
import { getApi, queryDeleteFile, queryGetPrevFiles, querySortMedia } from "./Queries";
import { TDefaultFile } from "./Types";
import { Dashboard } from "@uppy/react";
// @ts-ignore uppy is not type-safety
import Polish from "@uppy/locales/lib/pl_PL";
import { DisplayFiles } from "./DisplayFiles/DisplayFiles";
import Cookies from "js-cookie";

function initUppy(
    api: string,
    nameType: string,
    model: string,
    privateField: string,
    access: string,
    objectId: string,
) {
    return new Uppy({
        id: "upload files",
        autoProceed: true,
        meta: { nameType: nameType, model: model, private: privateField, access: access, objectId: objectId },
        allowMultipleUploads: false,
    }).use(XHR, {
        endpoint: `${api}/upload`,
        allowedMetaFields: ["name", "nameType", "model", "objectId", "sort", "sortIndex", "private", "access"],
        getResponseError(respText) {
            return new Error(JSON.parse(respText).error);
        },
    });
}

export const FileUploaderClean = ({
    model,
    typeName,
    objectId,
    numberOfFiles,
    fileExt,
    label,
    onlyDisplay,
}: {
    model: string;
    typeName: string;
    objectId: number;
    numberOfFiles?: number;
    fileExt?: string[];
    label?: string;
    onlyDisplay?: boolean;
}) => {
    const [open, setOpen] = useState<boolean>(false);
    const buttonRef = useRef(null);
    const [files, setFiles] = useState<TDefaultFile[]>([]);
    const [error, setError] = useState<string>("");

    const api = getApi();
    if (api === undefined) {
        console.log("[FileUploader] API is undefined");
    }
    const [uppy] = useState(() => initUppy(api ? api : "", typeName, model, "0", "", `${objectId}`));

    // calc actual possibly number of files
    const properNumberOfFiles = useMemo<number | null>(() => {
        if (!numberOfFiles) return null;
        const value = numberOfFiles - files.length;
        if (value > 0) return value;
        return -1;
    }, [numberOfFiles, files]);

    // set uppy allowed file extensions
    useEffect(() => {
        if (fileExt && fileExt.length > 0) {
            uppy.setOptions({ restrictions: { allowedFileTypes: fileExt } });
        }
    }, [fileExt]);

    // set uppy number of files
    useEffect(() => {
        if (properNumberOfFiles !== null) {
            uppy.setOptions({
                restrictions: { maxNumberOfFiles: properNumberOfFiles },
            });
        }
    }, [properNumberOfFiles]);

    useEffect(() => {
        (async () => {
            const prevFiles: TDefaultFile[] = await queryGetPrevFiles(`${objectId}`, model, typeName);
            setFiles(prevFiles);
        })();
    }, []);

    // on upload complete fetch files
    useEffect(() => {
        // @ts-ignore uppy is not type-safety
        const handler = async (_) => {
            const prevFiles: TDefaultFile[] = await queryGetPrevFiles(`${objectId}`, model, typeName);
            setFiles(prevFiles);
            uppy.cancelAll();
            setOpen(false);
        };

        uppy.on("complete", handler);
        return () => {
            uppy.off("complete", handler);
        };
    }, []);

    // on error display error
    useEffect(() => {
        // @ts-ignore uppy is not type-safety
        const handler = (error) => {
            console.error(error);
            setError(error.message);
        };

        uppy.on("error", handler);
        return () => {
            uppy.off("error", handler);
        };
    }, []);

    // add file trigger
    useEffect(() => {
        // @ts-ignore uppy is not type-safety
        const handler = (x) => {
            uppy.setFileState(x.id, {
                xhrUpload: {
                    headers: {
                        Authorization: "Token " + Cookies.get("jwt"),
                    },
                },
            });

            uppy.setFileMeta(x.id, { sort: files.map((file) => file.key), sortIndex: files.length });
        };

        uppy.on("file-added", handler);
        return () => {
            uppy.off("file-added", handler);
        };
    }, []);

    const moveHandler = async (direction: number, key: string) => {
        const index = files.findIndex((el) => el.key === key);

        if (index === -1) return;

        const newSort = [...files];

        const newIndex = index + direction;

        if (newIndex >= 0 && newIndex < newSort.length) {
            [newSort[index], newSort[newIndex]] = [newSort[newIndex], newSort[index]];
        }

        const isSet = await querySortMedia(
            typeName,
            model,
            `${objectId}`,
            newSort.filter((el) => el.uploaded).map((el) => el.key),
        );
        if (isSet) {
            setFiles(newSort);
        }
    };

    const deleteFileHandler = (file: TDefaultFile) => {
        queryDeleteFile(file.key).then((isDeleted) => {
            if (isDeleted) {
                setFiles((prev) => deleteFromStatus(prev, file));
            } else {
                console.error("Delete Failed");
            }
        });
    };

    const deleteFromStatus = (prev: TDefaultFile[], file: TDefaultFile) => {
        return prev.filter((el) => file.key !== "" && file.key !== el.key);
    };

    return (
        <div>
            {!!label && <div className="inline-block mb-2 text-black ">{label}</div>}
            {files && (
                <DisplayFiles
                    data={files}
                    deleteFn={deleteFileHandler}
                    moveHandler={moveHandler}
                    thumbnails={[]}
                    name={typeName}
                    copyLink={true}
                    onlyDisplay={onlyDisplay}
                />
            )}
            {!onlyDisplay && (
                <button
                    className={
                        "border flex gap-2 items-center px-3 py-1 rounded-md bg-[#0078d7] text-white border-[#0078d7] hover:bg-sky-800"
                    }
                    onClick={() => setOpen(true)}
                    ref={buttonRef}
                >
                    <Plus size={18} />
                    Dodaj pliki
                </button>
            )}
            {error && <div style={{ margin: "1rem", color: "red" }}>{error}</div>}
            {open && (
                <Modal show={true} onHide={() => setOpen(false)} shadow={false} relativeTo={() => buttonRef.current}>
                    <Dashboard
                        data-testid={"dashboard"}
                        uppy={uppy}
                        proudlyDisplayPoweredByUppy={false}
                        hideProgressAfterFinish={true}
                        showProgressDetails={true}
                        hideUploadButton={true}
                        hideCancelButton={true}
                        showRemoveButtonAfterComplete={true}
                        hideRetryButton={true}
                        theme={"dark"}
                        width={300}
                        height={200}
                        locale={Polish}
                    />
                </Modal>
            )}
        </div>
    );
};
