import { TDefaultFile, TThumbnailObj } from "../Types";
import { FileCard } from "./FileCard";

export const DisplayFiles = ({
    data,
    name,
    deleteFn,
    moveHandler,
    thumbnails,
    copyLink,
    onlyDisplay,
}: {
    data: TDefaultFile[];
    name: string;
    deleteFn: (file: TDefaultFile) => void;
    moveHandler: (direction: number, key: string) => void;
    thumbnails?: TThumbnailObj[];
    copyLink?: boolean;
    onlyDisplay?: boolean;
}) => {
    return (
        <>
            <div className={`${"flex flex-wrap gap-2 mb-2"} ${"swapy_id"}`}>
                {data.map((file, index) => (
                    <div key={index} data-swapy-slot={index}>
                        {file ? (
                            <FileCard
                                file={file}
                                index={index}
                                isFirst={index === 0}
                                isLast={index === data.length - 1}
                                name={name}
                                deleteFn={deleteFn}
                                moveHandler={moveHandler}
                                onlyDisplay={onlyDisplay}
                                copyLink={copyLink}
                                thumbnail={
                                    file.key && !file.uploaded && thumbnails
                                        ? thumbnails.find((thumb) => thumb.id === file.key)?.thumbnail
                                        : undefined
                                }
                            />
                        ) : null}
                    </div>
                ))}
            </div>
        </>
    );
};
