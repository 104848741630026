import { TDefaultFile } from "../Types";
import { confirmDialog } from "../../../../ConfirmDialog";
import { getApi } from "../Queries";
import { Check, ChevronLeft, ChevronRight, Download, File, Link2, Upload, X } from "lucide-react";
import { ConfigKey, getEnv } from "@as-pl/env/getEnv";
import { useState } from "react";
import { Modal } from "../../../../Modal";

export const FileCard = ({
    file,
    name,
    index,
    deleteFn,
    moveHandler,
    thumbnail,
    onlyDisplay,
    isFirst,
    copyLink,
    isLast,
}: {
    file: TDefaultFile;
    name: string;
    index: number;
    deleteFn: (file: TDefaultFile) => void;
    moveHandler: (direction: number, key: string) => void;
    thumbnail?: string;
    copyLink?: boolean;
    onlyDisplay?: boolean;
    isFirst: boolean;
    isLast: boolean;
}) => {
    const [preview, setPreview] = useState<string | null>(null);

    const getMediaLink = (w: number, h: number) => {
        const host = getEnv(ConfigKey.NEXT_PUBLIC_MEDIA_SERVER_ADDRESS) || getEnv(ConfigKey.MEDIA_SERVER_ADDRESS) || "";
        return host + `/pub/${file.key}/${checkName(file.name)}?w=${w}&h=${h}`;
    };

    const checkName = (name: string) => {
        const newName = name.split(".");
        if (newName.length > 1) {
            return "";
        }
        return name;
    };

    const deleteHandler = () => {
        confirmDialog("Czy na pewno chcesz usunąć ten plik?").then((confirm) => {
            if (confirm) {
                deleteFn(file);
            }
        });
    };

    const isImageExtension = (name: string, path: string) => {
        const extensions = [".png", ".jpg", ".jpeg", ".apng", ".avif", ".webp"];

        const checkExtension = (filePath: string) => {
            const extension = filePath.split(".").pop();
            return extension ? extensions.includes("." + extension.toLowerCase()) : false;
        };

        return checkExtension(name) || checkExtension(path);
    };

    const createDownloadLink = async () => {
        const id = file.key + file.name + file.path;
        const hashBuffer = await crypto.subtle.digest("SHA-256", new TextEncoder().encode(id));
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("");

        return `${getApi()}/media/${file.key}/${hashHex}`;
    };

    const downloadFileSimulation = async () => {
        const url = await createDownloadLink();
        const link = document.createElement("a");
        link.target = "_blank";
        link.rel = "noopener noreferrer";
        link.href = url;
        link.click();
    };

    return (
        <div
            className="bg-transparent w-[150px] h-[160px] inline-block relative border border-[#cfcfcf] overflow-hidden hover:border-black hover:cursor-pointer group transition-all select-none"
            key={index}
        >
            <div className="absolute top-1 left-1 flex">
                {!onlyDisplay && (
                    <a
                        className="p-0.5 rounded-md cursor-pointer  hover:text-red-600 hover:bg-neutral-200 hidden group-hover:block"
                        onClick={deleteHandler}
                    >
                        <X size={20} />
                    </a>
                )}
                {copyLink && (
                    <a
                        className="p-0.5 rounded-md cursor-pointer hover:text-sky-600 hover:bg-neutral-200 hidden group-hover:block"
                        onClick={async () => {
                            const x = await createDownloadLink();
                            navigator.clipboard.writeText(x);
                        }}
                    >
                        <Link2 size={20} />
                    </a>
                )}
            </div>

            {!isFirst && !onlyDisplay && (
                <a
                    className="absolute p-0.5 rounded-md cursor-pointer top-[35%] left-1 hover:text-sky-600 hover:bg-neutral-200 hidden group-hover:block"
                    onClick={() => moveHandler(-1, file.key)}
                >
                    <ChevronLeft size={25} />
                </a>
            )}

            {!isLast && !onlyDisplay && (
                <a
                    className="absolute p-0.5 rounded-md cursor-pointer top-[35%] right-1 hover:text-sky-600 hover:bg-neutral-200 hidden group-hover:block"
                    onClick={() => moveHandler(1, file.key)}
                >
                    <ChevronRight size={25} />
                </a>
            )}

            <div className="absolute p-2 right-1" style={file.uploaded ? { color: "green" } : { color: "red" }}>
                {file.uploaded ? <Check size={20} /> : <Upload size={20} />}
            </div>
            <div className="flex items-center justify-center w-full h-full">
                {file.uploaded ? (
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={async () => {
                            if (name === "images" || isImageExtension(file.name, file.path)) {
                                setPreview(getMediaLink(800, 600));
                            } else {
                                await downloadFileSimulation();
                            }
                        }}
                        draggable={false}
                    >
                        {name === "images" || isImageExtension(file.name, file.path) ? (
                            <img src={getMediaLink(100, 100)} width={100} height={100} alt="img" draggable={false} />
                        ) : (
                            <File size={70} color="#303030" />
                        )}
                    </a>
                ) : thumbnail ? (
                    <img src={thumbnail} width={100} height={100} alt="img" draggable={false} />
                ) : (
                    <File size={70} color="#eee" />
                )}
            </div>
            <p className="text-center bg-gray-200 p-1.5 absolute bottom-0 left-0 right-0 opacity-80  truncate   whitespace-nowrap h-8">
                {file.name}
            </p>
            {preview && (
                <Modal show={true} onHide={() => setPreview(null)}>
                    <div className="relative">
                        <a
                            className="absolute p-1 text-white rounded-md cursor-pointer bg-neutral-600 top-2 right-2 hover:bg-neutral-700"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={downloadFileSimulation}
                            draggable={false}
                        >
                            <Download />
                        </a>
                        <img src={preview} alt="preview" />
                    </div>
                </Modal>
            )}
        </div>
    );
};
